<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, toRefs, defineEmits, computed } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { urlCompletion } from '@/util/func'
  import JLTools from '@/util/JLTools'
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    dataObj: {
      type: Object,
      required: true
    }
  })

  const { dataObj } = toRefs(props)

  const backgroundURL = computed(() => {
    let coverImg = dataObj.value.data && dataObj.value.data[0].coverImg ? dataObj.value.data[0].coverImg : ''
    // return JLTools.formatUrl(coverImg)
    return coverImg
  })

  const goLink = (item) => {
    // console.log('跳转', item, isLink())
    if (!isLink()) return
    if (item.data[0].linkUrl === 'activityLink') {
      const url = urlCompletion(item.data[0].linkAddress)
      return window.open(url, '_blank')
    }

    const { href } = proxy.$router.resolve({
      name: 'productMain',
      params: {
        id: item.data[0].id
      }
    })
    window.open(href, '_blank')
  }

  // 判定是否可跳转
  const isLink = () => {
    // console.log('是否可以跳转')
    let isLink = false
    if (dataObj.value.data[0].linkUrl === 'goodDetail' && dataObj.value.data[0].id) {
      isLink = true
    }
    if (dataObj.value.data[0].linkUrl === 'activityLink' && dataObj.value.data[0].linkAddress) {
      isLink = true
    }
    return isLink
  }
</script>

<template>
  <div class="wrap-box" :style="{ marginBottom: dataObj.distance ? dataObj.distance + 'px' : '0', cursor: isLink ? 'pointer' : 'auto' }">
    <div class="title" v-if="dataObj.title">{{ dataObj.title }}</div>
    <div class="subtitle" v-if="dataObj.subTitle">{{ dataObj.subTitle }}</div>
    <div class="container" :style="{ width: dataObj.isHaveMargin ? '100%' : '1200px' }" @click="goLink(dataObj)">
      <JLImg class="image" :src="backgroundURL"></JLImg>
      <div class="plainText-box">
        <div class="plainText columnCS">
          <div class="container-title">{{ dataObj.bannerTitle }}</div>
          <div class="container-subtitle">{{ dataObj.bannerDesc }}</div>
          <div v-if="dataObj.isShowLearnMore">
            <div class="container-learn-more">Learn More ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
  .wrap-box {
    width: 100%;
    min-height: 200px;
    max-width: var(--minWidth);
    margin: 0 auto;
    background: #fff;
    .title {
      font-weight: 600;
      font-size: 22px;
    }

    .subtitle {
      font-weight: 400;
      font-size: 16px;
      color: var(--subTitleColor);
      padding-bottom: 32px;
    }
  }

  .container {
    position: relative;
    max-width: var(--minWidth);
    height: auto; /* 高度自适应 */
    margin: 0 auto;
    border-radius: 5px;
    .image {
      width: 100%;
      border-radius: 4px;
    }
  }

  .container .plainText-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .plainText {
      height: 100%;
      margin-left: 100px;
      .container-title {
        font-weight: 500;
        font-size: 40px;
        line-height: 49px;
        color: #ffffff;
      }

      .container-subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 33px;
        letter-spacing: 0.4px;
        color: #ffffff;
        padding: 5px 0;
      }

      .container-learn-more {
        font-weight: 700;
        font-size: 21px;
        line-height: 33px;
        letter-spacing: 0.4px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
</style>
