<script setup>
  import commodityItem from '@/views/index/compontent/commodity-item'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch, computed } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    dataObj: {
      type: Object
    },
    type: {
      type: Number,
      default: 4
    }
  })

  const data = reactive({
    loading: false,
    discountsObj: [],
    discountsList: [],
    systemDate: null,
    timer: null
  })

  const viewAll = () => {
    console.log('查看所有', props.dataObj)
    router.push({
      name: 'productList2',
      query: {
        homeModeId: props.dataObj.discountId
      }
    })
  }

  // const hasActivityEnd = () => {
  //   if (data.discountsObj && data.discountsObj.endAt) {
  //     const currentTime = new Date(data.discountsObj.systemDate).getTime()
  //     const endAt = new Date(data.discountsObj.endAt).getTime()
  //     // return d&& new Date(data.discountsObj.endAt).getTime() > currentTime
  //   }
  // }

  // 获取折扣活动列表
  const getDiscountsList = (id) => {
    data.loading = true
    api.product.getDiscountsList(id).then((res) => {
      console.log(res, res.data.productDiscountDtoList.content, '获取折扣活动列表')
      data.discountsObj = res.data.discountInfoSimpleDto
      data.discountsList = res.data.productDiscountDtoList.content || []
      data.systemDate = new Date(data.discountsObj.systemDate).getTime()
      updateTime()
      data.timer = setInterval(updateTime, 1000)
      data.loading = false
    })
  }

  // 计算倒计时时间
  const displayValue = computed(() => {
    const systemDate = data.systemDate
    // console.log(data.systemDate, 'data.systemDate')
    let endTime = null
    let startTime = null
    let status = null

    if (systemDate > new Date(data.discountsObj.preBeginAt).getTime() && new Date(data.discountsObj.beginAt).getTime() > systemDate) {
      status = 'Starting In'
      endTime = new Date(data.discountsObj.beginAt).getTime()
      startTime = systemDate
    } else if (new Date(data.discountsObj.beginAt).getTime() < systemDate && systemDate < new Date(data.discountsObj.endAt).getTime()) {
      status = 'Ends in'
      endTime = new Date(data.discountsObj.endAt).getTime()
      startTime = systemDate
    } else {
      status = 'Ended'
      endTime = systemDate
      startTime = new Date(data.discountsObj.endAt).getTime()
    }

    const diff = endTime - startTime
    console.log(diff, 'diff')

    if (diff <= 0) {
      clearInterval(data.timer)
      return {
        status: status,
        hours: '00',
        minutes: '00',
        seconds: '00'
      }
    }

    const hours = String(Math.floor((diff / (1000 * 60 * 60)) % 24)).padStart(2, '0')
    const minutes = String(Math.floor((diff / (1000 * 60)) % 60)).padStart(2, '0')
    const seconds = String(Math.floor((diff / 1000) % 60)).padStart(2, '0')
    return { status, hours, minutes, seconds }
  })

  const updateTime = () => {
    data.systemDate = data.systemDate + 1000
  }

  onMounted(() => {})

  watch(
    props.dataObj,
    (newVal, oldVal) => {
      if (newVal.discountId) {
        console.log('查询折扣接口', newVal.discountId)
        getDiscountsList(newVal.discountId)
      }
    },
    { immediate: true }
  )
</script>

<template>
  <!-- && hasActivityEnd() -->
  <div v-if="data.discountsList.length > 0">
    <div class="commodity-list-wrap" :style="{ marginBottom: dataObj.distance ? dataObj.distance + 'px' : '0' }">
      <div class="commodity-item-box">
        <div class="header-box rowBS">
          <div class="rowSC">
            <div class="discount-ico"></div>
            <p class="title" v-if="props.dataObj.title.trim()">{{ props.dataObj.title }}</p>
            <div class="rowCC ml-16px" v-if="displayValue.status !== 'Ended'">
              <div class="deadline-status">{{ displayValue.status }}</div>
              <div class="deadline rowCC" :class="{ 'deadline-ended': displayValue.status === 'Ended' }">
                <div class="countdown-time rowCC">{{ displayValue.hours }}</div>
                <div class="dian">:</div>
                <div class="countdown-time rowCC">{{ displayValue.minutes }}</div>
                <div class="dian">:</div>
                <div class="countdown-time rowCC">{{ displayValue.seconds }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="view-all" v-if="data.discountsList.length > props.type" @click="viewAll">View All</div> -->
        </div>
        <div class="card-box-container">
          <template v-if="data.loading === true">
            <div v-for="(item, index) in Number(props.type)" :key="index" :style="{ flex: `1 0 calc((100% - ${props.type} * 16px ) / ${props.type})` }">
              <el-skeleton style="width: 288px" :loading="true" animated>
                <template #template>
                  <el-skeleton-item variant="image" style="width: 288px; height: 288px" />
                  <div style="padding: 16px 12px">
                    <el-skeleton :rows="2" />
                  </div>
                </template>
              </el-skeleton>
            </div>
          </template>
          <template v-else>
            <div
              v-for="item of data.discountsList.slice(0, props.type)"
              :key="item.id"
              class="card-box"
              :style="{ flex: `1 0 calc((100% - ${props.type} * 16px ) / ${props.type})` }"
            >
              <commodity-item :item="item" :isDiscountStatus="displayValue.status === 'Ends in'" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .commodity-list-wrap {
    width: var(--minWidth);
    margin: 0 auto;
    font-size: 16px;
    .header-box {
      margin-bottom: 32px;
      .title {
        font-weight: 600;
        font-size: 22px;
      }

      .discount-ico {
        width: 22px;
        height: 22px;
        margin-right: 4px;
        background: url('https://cdn.pricoo.pk/b4428734.png') no-repeat;
        background-size: 100% 100%;
      }

      .deadline-status {
        color: #65656b;
      }

      .deadline {
        margin-left: 16px;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 600;
        color: #ff7a00;
        .countdown-time {
          width: 36px;
          height: 36px;
          border-radius: 4px;
          background: rgba(255, 137, 0, 0.3);
        }
        .dian {
          padding: 0 4px;
        }
        :deep(.el-statistic__number) {
          color: #ff7a00;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .deadline-ended {
        color: #909399;
        .countdown-time {
          background: #f4f4f5;
        }
      }

      .subtitle {
        font-weight: 400;
        font-size: 16px;
        color: var(--subTitleColor);
      }
      .view-all {
        cursor: pointer;
        font-size: 20px;
        line-height: 36px;
        color: var(--Zcolor);
        text-decoration: underline;
      }
    }
    .card-box-container {
      width: 100%;
      display: flex;
    }
    .card-box {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
