<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()
  const props = defineProps({
    dataObj: {
      type: Array
    }
  })
</script>

<template>
  <div class="page-content">
    <div v-html="dataObj.editorContent "></div>
  </div>
</template>

<style lang="less" scoped>
  .page-content {
    width: var(--minWidth);
    margin: 0 auto;
  }
</style>
