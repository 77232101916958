<script setup>
  import safeguardList from '@/views/index/compontent/safeguard-list'
  import bannerList from '@/views/index/compontent/banner'
  import discountBar from '@/views/index/compontent/discount-bar'
  import commodityList from '@/views/index/compontent/commodity-list'
  import editBanner from './compontent/edit-banner'
  import multicircuitBanner from './compontent/multicircuit-banner'
  import sidePicture from './compontent/side-picture'
  import textVideo from './compontent/text-video'
  import textImageBar from '@/views/index/compontent/text-image-bar'
  import sidePictureBolg from '@/views/index/compontent/side-picture-bolg'
  import webArticle from '@/views/index/compontent/web-article'
  import { ElCarousel, ElCarouselItem } from 'element-plus'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore, HsystemStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    configInfo: [],
    bannerList: [], // 轮播图列表
    otherItems: [], // 其他数据
  })

  // 获取首页配置
  const getCustomPages = () => {
    const parms = {
      'scene.equals': 'WEB_HOMEPAGE',
      'executed.equals': 'true',
      // 'id.equals': '32',
      'show.equals': true
    }
    api.common.getCustomPages(parms).then((res) => {
      if (JLTools.isNull(res)) return
      data.configInfo = JSON.parse(res[0].configInfo)
      HsystemStore.setHomeData(data.configInfo)
      console.log('configInfo', data.configInfo)
      handleHomeData()
    })
  }

  const handleHomeData = () => {
    // 分割数组
    const [bannerList, otherItems] = data.configInfo.reduce(
      (acc, item, index) => {
        if (item.configType === 'SubMenuAndBanner' && item.bannerShowType === 'default') {
          acc[0] = item.data
        } else {
          acc[1].push(item)
        }
        return acc
      },
      [[], []]
    )
    console.log('otherItems', otherItems)
    data.bannerList = bannerList
    data.otherItems = otherItems
  }

  const init = () => {
    if (HsystemStore.homeData.length) {
      // 存在直接处理配置
      data.configInfo = HsystemStore.homeData
      handleHomeData()
    } else {
      // 获取首页配置
      getCustomPages()
    }
  }

  onMounted(() => {
    init()
  })
</script>

<template>
  <div ref="content" class="page-box">
    <div class="wh100">
      <bannerList v-if="data.bannerList.length" :list="data.bannerList" />
      <el-skeleton-item v-else variant="image" style="width: 100%; height: 400px" animated />
    </div>

    <safeguard-list></safeguard-list>

    <div v-for="(item, index) in data.otherItems" :key="index">
      <!-- {{index}}{{item.configType}} -->
      <template v-if="item.configType === 'WebDiscountBar'" data-remark="折扣活动">
        <discount-bar :dataObj="item" :type="item.showNumbers"></discount-bar>
      </template>
      <template v-if="item.configType === 'WebFourProductsBar'" data-remark="4个商品元素">
        <commodity-list :dataObj="item" type="4"></commodity-list>
      </template>
      <template v-if="item.configType === 'WebProductBar'" data-remark="5个商品元素">
        <commodity-list :dataObj="item" type="5"></commodity-list>
      </template>
      <template v-if="item.configType === 'WebSixProductsBar'" data-remark="6个商品元素">
        <commodity-list :dataObj="item" type="6"></commodity-list>
      </template>
      <template v-if="item.configType === 'SubMenuAndBanner' && item.bannerShowType === 'editableText'" data-remark="单张图片">
        <edit-banner :dataObj="item" />
      </template>
      <template v-if="item.configType === 'AnchorBanner'" data-remark="带多点击事件的图片">
        <multicircuit-banner :dataObj="item" />
      </template>
      <template v-if="item.configType === 'SidePicture'" data-remark="左右图片">
        <side-picture :dataObj="item" />
      </template>
      <template v-if="item.configType === 'BlogBar' && userStore.isDev" data-remark="左右图片&&博客">
        <side-picture-bolg :dataObj="item" />
      </template>
      <template v-if="item.configType === 'TextImageBar'" data-remark="左1张图片|右两张图片">
        <text-image-bar :dataObj="item" />
      </template>
      <template v-if="item.configType === 'TextVideo'" data-remark="左文字右视频">
        <text-video :dataObj="item" />
      </template>
      <template v-if="item.configType === 'WebArticle'" data-remark="文章">
        <web-article :dataObj="item" />
      </template>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .page-box {
    position: relative;
    width: 100%;
    min-height: 500px;
    background: #fff;
  }
</style>
