<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, toRefs, defineEmits, computed } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { urlCompletion } from '@/util/func'
  import JLTools from '@/util/JLTools'
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    dataObj: {
      type: Object,
      required: true
    }
  })
  const videoRef = ref(null)
  const { dataObj } = toRefs(props)
  const data = reactive({
    curIndex: 0,
    isPaly: false
  })

  const backgroundURL = computed(() => {
    let coverImg = dataObj.value && dataObj.value.bannerImg ? dataObj.value.bannerImg : ''
    // return JLTools.formatUrl(coverImg)
    return coverImg
  })

  const goLink = (id) => {
    const { href } = proxy.$router.resolve({
      name: 'productMain',
      params: {
        id: id
      }
    })
    window.open(href, '_blank')
  }

  const videoPlay = () => {
    const videoElement = videoRef.value
    if (videoElement.paused) {
      videoElement.play()
      data.isPaly = true
    }
  }
</script>

<template>
  <div class="wrap-box" :style="{ marginBottom: dataObj.distance ? dataObj.distance + 'px' : '0' }">
    <div class="container rowSC">
      <div class="left-box columnCS">
        <div class="little-title">{{ dataObj.littleTitle }}</div>
        <div class="p1">{{ dataObj.title }}</div>
        <div class="p2">{{ dataObj.description }}</div>
      </div>
      <div class="right-box">
        <div v-if="!data.isPaly" class="poster-box">
          <JLImg :src="dataObj.videoCoverImg"></JLImg>
          <i class="iconfont icon-bofang bofang-pos" @click="videoPlay"></i>
        </div>
        <video v-show="data.isPaly" ref="videoRef" width="768" controls="controls">
          <source :src="dataObj.videoSrc" type="video/mp4" />
          Sorry, your browser does not support embedded video
        </video>

        <!-- <video ref="videoRef" width="768" controls="controls" :poster="JLTools.formatUrl(dataObj.videoCoverImg)">
          <source :src="dataObj.videoSrc" type="video/mp4" />
          Sorry, your browser does not support embedded video
        </video>
        <i class="iconfont icon-bofang bofang-pos" @click="videoPlay"></i> -->
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
  .wrap-box {
    width: 100%;
    min-height: 200px;
    max-width: var(--minWidth);
    margin: 0 auto;
    background: #fff;
    .title {
      font-weight: 600;
      font-size: 22px;
    }

    .subtitle {
      font-weight: 400;
      font-size: 16px;
      color: var(--subTitleColor);
      padding-bottom: 32px;
    }
  }

  .container {
    position: relative;
    max-width: var(--minWidth);
    height: auto; /* 高度自适应 */
    margin: 0 auto;
    .left-box {
      height: 360px;
      .little-title {
        font-size: 14px;
        font-weight: 600;
        color: var(--Zcolor);
      }
      .p1 {
        margin-top: 8px;
        font-size: 40px;
        font-weight: 700;
        color: var(--titleColor);
      }
      .p2 {
        margin-top: 8px;
        font-size: 16px;
        color: var(--subTitleColor);
      }
    }
    .right-box {
      position: relative;
      flex: 0 0 768px;
      margin-left: 24px;
      cursor: pointer;
      border-radius: 4px;
      padding: 0;
      border: none;
      video {
        border-radius: 4px;
      }
      .poster-box {
        position: relative;
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
      }
      .bofang-pos {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
        height: 60px;
        font-size: 60px;
        z-index: 999;
        /* color: var(--Zcolor); */
        color: #fff;
      }
    }
  }
</style>
