<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const safeguardList = [
    { type: 'Responsive', typeName: '', lable: 'Quick Response', content: 'Efficient Customer Service', ico: 'https://cdn.pricoo.pk/cf118c84.png' },
    {
      type: 'Shipping',
      typeName: '',
      lable: 'Express Delivery',
      content: 'Quick, Free, Fast and Reliable Deliveries',
      ico: 'https://cdn.pricoo.pk/a9c019b8.png'
    },
    { type: 'Responsive', typeName: '', lable: 'Easy Returns', content: '', ico: 'https://cdn.pricoo.pk/aa42f5e0.png' },
    { type: 'Secure', typeName: '', lable: 'Secure Payment', content: 'Reliable & Hassle Free Payment', ico: 'https://cdn.pricoo.pk/2579b531.png' },
    { type: 'Responsive', typeName: '', lable: 'Huge Discounts', content: '', ico: 'https://cdn.pricoo.pk/1479102e.png' },
    { type: 'Responsive', typeName: '', lable: 'Original Products', content: '', ico: 'https://cdn.pricoo.pk/fffd4e84.png' }
  ]
</script>

<template>
  <div class="rowBC safeguard-box">
    <div v-for="(item, index) in safeguardList" :key="index">
      <div class="rowSC">
        <div class="ico" :style="{ width: `${item.width}px`, height: `${item.height}px`, backgroundImage: `url(${item.ico})` }"></div>
        <div class="columnBS">
          <div class="lable">{{ item.lable }}</div>
          <!-- <div class="mt5">{{ item.content }}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped> 
  .safeguard-box {
    width: var(--minWidth);
    margin: 12px auto 30px;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    color: #404040;
    .ico {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      background-size: 100% 100%;
    }
    .shipping-ico {
      width: 64px;
      height: 32px;
    }
    .lable {
      font-weight: 600;
    }
  }
</style>
