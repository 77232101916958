<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, toRefs, defineEmits, computed } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { urlCompletion } from '@/util/func'
  import JLTools from '@/util/JLTools'
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    dataObj: {
      type: Object,
      required: true
    }
  })

  const { dataObj } = toRefs(props)
  const data = reactive({
    curIndex: 0
  })

  const backgroundURL = computed(() => {
    let coverImg = dataObj.value && dataObj.value.bannerImg ? dataObj.value.bannerImg : ''
    // return JLTools.formatUrl(coverImg)
    return coverImg
  })

  const bannerClass = computed(() => {
    return dataObj.value.bannerShowType === 'leftSmall' ? 'left-small-box' : 'left-big-box'
  })

  const goLink = (item) => {
    // console.log('跳转', item, isLink())
    if (!isLink(item)) return
    if (item.linkUrl === 'activityLink') {
      return linkUrl(item.linkAddress)
    }

    const { href } = proxy.$router.resolve({
      name: 'productMain',
      params: {
        id: item.id
      }
    })
    window.open(href, '_blank')
  }

  const linkUrl = (link) => {
    if (!link) return
    const url = urlCompletion(link)
    window.open(url, '_blank')
  }

  // 判定是否可跳转
  const isLink = (item) => {
    // console.log('是否可以跳转')
    let isLink = false
    if (item.linkUrl === 'goodDetail' && item.id) {
      isLink = true
    }
    if (item.linkUrl === 'activityLink' && item.linkAddress) {
      isLink = true
    }
    return isLink
  }
</script>

<template>
  <div class="wrap-box" :style="{ marginBottom: dataObj.distance ? dataObj.distance + 'px' : '0' }">
    <div class="container rowBC" :class="bannerClass">
      <div class="left-box columnCS">
        <div class="p1" :style="{ fontSize: dataObj.titleFontSize + 'px' }">{{ dataObj.title }}</div>
        <div class="btn hr-primary-btn" @click="linkUrl(dataObj.buttonLink)">{{ dataObj.buttonText }}</div>
      </div>
      <div class="right-box rowCC">
        <div v-for="(item, index) in dataObj.data" :key="index" class="image-box" @click="goLink(item)">
          <JLImg class="image" :src="item.coverImg" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
  .wrap-box {
    width: 100%;
    min-height: 200px;
    max-width: var(--minWidth);
    margin: 0 auto;
    background: #fff;
  }

  .container {
    position: relative;
    max-width: var(--minWidth);
    height: auto; /* 高度自适应 */
    margin: 0 auto;
    .left-box {
      flex: 0 0 584px;
      .p1 {
        font-size: 40px;
        font-weight: 700;
      }
      .btn {
        width: fit-content;
        padding: 16px 32px;
        margin-top: 16px;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;
      }
    }
    .image-box {
      .image {
        width: 288px;
        height: 211px;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
      }
      &:first-child {
        margin-right: 16px;
      }
    }
  }
</style>
