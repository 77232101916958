<script setup>
  import commodityItem from '@/views/index/compontent/commodity-item'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    dataObj: {
      type: Object
    },
    type: {
      type: [Number, String],
      default: 4
    }
  })

  const viewAll = () => {
    console.log('查看所有')
  }
</script>

<template>
  <div class="commodity-list-wrap" :style="{ marginBottom: dataObj.distance ? dataObj.distance + 'px' : '0' }">
    <div class="commodity-item-box">
      <div class="header-box rowBS">
        <div>
          <p class="title" v-if="props.dataObj.title.trim()">{{ props.dataObj.title }}</p>
          <p class="subtitle" v-if="props.dataObj.subTitle.trim()">{{ props.dataObj.subTitle }}</p>
        </div>
        <!-- <div class="view-all" v-if="props.dataObj.data.length > props.type" @click="viewAll">View All</div> -->
      </div>
      <div class="card-box-container">
        <div v-for="item of props.dataObj.data.slice(0, props.type)" :key="item.id" class="card-box" :style="{ flex: `1 0 calc((100% - ${props.type} * 16px ) / ${props.type})` }">
          <commodity-item :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .commodity-list-wrap {
    width: var(--minWidth);
    margin: 0 auto;
    font-size: 16px;
    .header-box {
      margin-bottom: 32px;
      .title {
        font-weight: 600;
        font-size: 22px;
      }

      .subtitle {
        font-weight: 400;
        font-size: 16px;
        color: var(--subTitleColor);
      }
      .view-all {
        cursor: pointer;
        font-size: 20px;
        line-height: 36px;
        color: var(--Zcolor);
        text-decoration: underline;
      }
    }
    .card-box-container {
      width: 100%;
      display: flex;
    }
    .card-box {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
